<template>
  <div class="hold-transition login-page bg-login view-user-reset-password">
    <div id="app" class="login-box">
      <div class="card">
        <div class="card-body login-card-body">
          <template v-if="resetPasswordStatusActive == true">
            <template v-if="resetPasswordStatus == false">
              <div class="row">
                <div class="col-12 text-center mb-3">
                  <img
                    class="logo-login-img"
                    src="@/assets/img/dentcloud-logo-darkblue.png"
                  />
                  <h3 class="logo-caption mt-2">
                    <span class="">D</span>ent<span class="">C</span>loud
                  </h3>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h2>Set a new password!</h2>
                  <p>
                    Hint: The password should be at least 8 characters long. To
                    make it stronger, contain at least one letter (a-z) and one
                    number (0-9).
                  </p>
                </div>
              </div>
              <!-- <b-form submit.prevent="sendResetPassword"> -->
              <b-form>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="password">รหัสผ่าน</label>
                      <input
                        type="password"
                        class="form-control form-control-sm required"
                        id="password"
                        v-model="resetPasswordForm.password"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="confirmPassword">ยืนยันรหัสผ่าน</label>
                      <input
                        type="password"
                        class="form-control form-control-sm required"
                        id="confirmPassword"
                        v-model="resetConfirmPassword"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <button
                      @click="sendResetPassword"
                      type="button"
                      class="btn btn-block btn-primary btn-sm"
                    >
                      Reset password
                    </button>
                  </div>
                </div>
              </b-form>
            </template>
            <template v-if="resetPasswordStatus == true">
              <div class="row">
                <div class="col-12">
                  <h2 class="text-success">
                    <i class="fas fa-check-circle"></i> SUCCESS!!
                  </h2>
                  <p>Awesome, you've successfully update your password.</p>
                  <a href="/login" class="btn btn-block btn-primary"
                    >GO TO LOGIN</a
                  >
                </div>
              </div>
            </template>
          </template>
          <template v-if="resetPasswordStatusActive == false">
            <div class="row">
              <div class="col-12 text-danger">
                <h2>
                  <i class="fas fa-exclamation-triangle"></i> Sorry, something
                  went wrong!!
                </h2>
              </div>
              <div class="col-12">
                Sorry, something went wrong.
                <a href="/login">Log in</a> or go back to the
                <a href="/queuemanage">dashboard</a>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "ResetPasswordViews",
  components: {},
  data() {
    return {
      resetPasswordForm: {
        password: "",
        resetPasswordKey: "",
      },
      resetConfirmPassword: "",
      resetPasswordStatus: false,
      resetPasswordStatusActive: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions({
      checkResetPasswordKey: "moduleUser/checkResetPasswordKey",
      resetPassword: "moduleUser/resetPassword",
    }),
    init() {
      this.resetPasswordForm.resetPasswordKey =
        this.$route.params.resetPasswordKey;
      this.checkResetPassword();
    },
    checkResetPassword() {
      this.checkResetPasswordKey({
        resetPasswordKey: this.resetPasswordForm.resetPasswordKey,
      }).then((res) => {
        if (res.status == 200) {
          this.resetPasswordStatusActive = true;
        }
      });
    },
    sendResetPassword() {
      this.resetPassword(this.resetPasswordForm).then((res) => {
        if (res.status == 200) {
          this.resetPasswordStatus = true;
        }
      });
    },
  },
};
</script>